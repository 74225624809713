<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form label-width="50px" class="topS">
          <el-row :gutter="10" style="margin-bottom: 0">
            <el-col :span="3">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandId"
                  @change="selectOnes"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in brandSelectDatas"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.hotelId"
                  @change="selectTwos"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in hotelSelectDatas"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="建筑">
                <el-select
                  v-model="seekData.buildingId"
                  @change="selectThrees"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in buildingSelectDatas"
                    :key="index"
                    :label="item.buildingName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="楼层">
                <el-select
                  v-model="seekData.floorId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in floorSelectDatas"
                    :key="index"
                    :label="item.floorName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="房间号" label-width="60px">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.roomNumber"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="主板IP" label-width="60px">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.mainBoardIp"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="UUID">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.mainBoardId"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-collapse-transition>
            <div v-show="foldData" style="margin-bottom: 10px">
              <el-row></el-row>
            </div>
          </el-collapse-transition> -->
        </el-form>
        <!-- 按钮行 -->
        <el-row>
          <el-col :span="19">
            <el-button @click="addBtn" v-if="showadd != -1">新增</el-button>
            <el-button @click="batchesDelete" v-if="showdelete != -1"
              >批量删除</el-button
            >
            <!-- <el-button @click="exportExcel">下载模板</el-button> -->
            <el-button @click="uploadExcel" v-if="showdownload != -1"
              >下载模板</el-button
            >
            <!-- <label class="control-label file">
              <input type="file" @change="importf(this)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
              导入excel
            </label>-->
            <label class="control-label file" v-if="showupload != -1">
              <input
                type="file"
                class="upload"
                @change="importExcel"
                ref="inputer"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              导入excel
            </label>
            <div style="color: #fff; display: inline-block">.</div>
          </el-col>
          <el-col :span="5" class="reset-button">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="reset">重置</el-button>
            <!-- <el-button plain class="my-icont" @click="fold">
              <div v-if="foldData">
                收起
                <i class="el-icon-arrow-up"></i>
              </div>
              <div v-else>
                展开
                <i class="el-icon-arrow-down"></i>
              </div>
            </el-button> -->
          </el-col>
        </el-row>
      </el-card>
    </el-row>

    <!-- 导出模板 -->
    <el-row style="display: none">
      <!-- <ExcelTemplate id="out-table"></ExcelTemplate> -->
    </el-row>

    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="brandName"
              label="品牌"
              width="80"
            ></el-table-column>
            <!-- <el-table-column prop="hotelId" label="门店ID"></el-table-column> -->
            <el-table-column
              prop="hotelName"
              label="门店"
              width="250"
            ></el-table-column>
            <!-- <el-table-column prop="floorId" label="建筑ID"></el-table-column> -->
            <el-table-column prop="id" label="客房ID"></el-table-column>
            <el-table-column
              prop="floorName"
              label="楼层"
              sortable
              width="80"
            ></el-table-column>
            <!-- <el-table-column prop="roomType" label="房间类型ID"></el-table-column> -->
            <el-table-column
              prop="roomTypeName"
              label="房间类型"
              width="150"
            ></el-table-column>

            <el-table-column
              prop="roomNumber"
              label="房间号"
              sortable
              width="100"
            ></el-table-column>
            <el-table-column
              prop="mainBoardIp"
              label="主板IP"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="mainBoardId"
              label="UUID"
              min-width="280"
            ></el-table-column>
            <!-- <el-table-column prop="MAC" label="MAC" min-width="280"></el-table-column>
      <el-table-column prop="DNS" label="DNS" min-width="280"></el-table-column> -->
            <!-- <el-table-column prop="remark" label="描述"></el-table-column> -->

            <!-- 操作按钮列 -->
            <el-table-column label="操作" width="300" fixed="right">
              <template slot-scope="scope">
                <el-button
                  v-if="showupdate != -1"
                  type="primary"
                  title="修改UUID"
                  size="mini"
                  dialogFormVisible
                  @click="handleEdit(scope.row, 'updatedUUid')"
                  >UUID修改</el-button
                >
                <!-- 编辑按钮 -->
                <el-button
                  v-if="showupdate != -1"
                  title="修改客房"
                  type="primary"
                  size="mini"
                  dialogFormVisible
                  @click="handleEdit(scope.row)"
                  >修改</el-button
                >
                <!-- 删除按钮 -->
                <el-button
                  v-if="showdelete != -1"
                  title="删除"
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
    <!-- 编辑的弹框 -->
    <el-dialog
      :title="updatedUUid ? '修改UUID' : '编辑客房'"
      :visible.sync="dialogFormVisible2"
      class="astrict"
    >
      <el-form :model="editData" ref="editData" :rules="myrules">
        <el-form-item
          v-if="!updatedUUid"
          label="主板IP"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="editData.mainBoardIp"
            placeholder="请输入内容"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="UUID"
          prop="mainBoardId"
          :label-width="formLabelWidth"
        >
          <el-input
            :disabled="!updatedUUid"
            v-model.trim="editData.mainBoardId"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="主机类型"
          prop="mainBoardType"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="editData.mainBoardType"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hostType"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="!updatedUUid"
          label="房间类型"
          prop="roomType"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="editData.roomType"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in roomTypeSelectData"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="!updatedUUid"
          label="房间号"
          prop="roomNumber"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="editData.roomNumber"
            placeholder="请输入内容"
            maxlength="15"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="!updatedUUid"
          label="PMS房间ID"
          prop="pmsRoomId"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="editData.pmsRoomId"
            placeholder="请输入内容"
            maxlength="15"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item v-if="!updatedUUid" label="MAC" prop="pmsRoomId" :label-width="formLabelWidth">
     <el-input v-model.trim="editData.pmsRoomId" placeholder="请输入内容" maxlength="15"></el-input>
    </el-form-item>
     <el-form-item v-if="!updatedUUid" label="DNS" prop="pmsRoomId" :label-width="formLabelWidth">
     <el-input v-model.trim="editData.pmsRoomId" placeholder="请输入内容" maxlength="15"></el-input>
    </el-form-item> -->
        <el-form-item
          v-if="!updatedUUid"
          label="描述"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="editData.remark"
            placeholder="请输入内容"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="!updatedUUid"
          label=""
          :label-width="formLabelWidth"
        >
          <el-button type="primary" @click="openDialog" :loading="loading"
            >查看设备信息</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancelEdit">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmEditD('editData')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 新增的弹框 -->
    <el-dialog
      title="新增客房"
      :visible.sync="dialogFormVisible"
      class="astrict"
    >
      <el-form :model="addform" ref="addform" :rules="myrules">
        <el-form-item label="品牌" prop="brandId" :label-width="formLabelWidth">
          <el-select
            v-model="addform.brandId"
            @change="selectOne"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in brandSelectData"
              :key="index"
              :label="item.brandName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="brandId" :label-width="formLabelWidth">
          <el-select
            v-model="addform.hotelId"
            @change="selectTwo"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hotelSelectData"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="建筑" prop="brandId" :label-width="formLabelWidth">
          <el-select
            v-model="addform.buildingId"
            @change="selectThree"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in buildingSelectData"
              :key="index"
              :label="item.buildingName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼层" prop="brandId" :label-width="formLabelWidth">
          <el-select
            v-model="addform.floorId"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in floorSelectData"
              :key="index"
              :label="item.floorName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="客房类型"
          prop="brandId"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="addform.roomType"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in roomTypeSelectData"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="房间号"
          prop="roomNumber"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="addform.roomNumber"
            placeholder="请输入内容"
            maxlength="15"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="PMS房间ID"
          prop="pmsRoomId"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="addform.pmsRoomId"
            placeholder="请输入内容"
            maxlength="15"
          ></el-input>
        </el-form-item>

        <el-form-item label="主板IP" :label-width="formLabelWidth">
          <el-input
            v-model.trim="addform.mainBoardIp"
            placeholder="请输入内容"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="UUID"
          prop="mainBoardId"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="addform.mainBoardId"
            placeholder="请输入内容"
            maxlength="36"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="主机类型"
          prop="mainBoardType"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="addform.mainBoardType"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hostType"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            v-model.trim="addform.remark"
            placeholder="请输入内容"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="abrogateAdd">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmAdd('addform')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 提示弹框 -->
    <el-dialog title="上传信息" :visible.sync="errorVisible" class="astrict">
      <div style="position: relative">
        <div
          class="flex-btn-right"
          style="position: absolute; top: 3px; right: 0; z-index: 1"
        >
          <span style="margin: 6px"
            >成功 {{ successData.length }} 条，失败
            {{ errorData.length }} 条，合计
            {{ successData.length + errorData.length }} 条</span
          >
          <el-button
            size="small"
            @click="exportExcel"
            v-if="activeName === 'second'"
            >导出数据</el-button
          >
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="成功记录" name="first">
            <el-table :data="successData" style="width: 100%">
              <el-table-column
                label="行"
                prop="row"
                width="80"
              ></el-table-column>
              <el-table-column
                label="房间号"
                prop="roomNumber"
                width="120"
              ></el-table-column>
              <el-table-column
                label="主板ID"
                prop="mainBoardId"
              ></el-table-column>
              <el-table-column
                label="导入时间"
                prop="createTime"
                width="140"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="失败记录" name="second">
            <template>
              <el-table :data="errorData" style="width: 100%" id="out-table">
                <el-table-column
                  prop="row"
                  label="行"
                  width="80"
                ></el-table-column>
                <el-table-column
                  prop="cellIndex"
                  label="列"
                  width="80"
                ></el-table-column>
                <el-table-column
                  prop="column"
                  label="字段"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="errorMessage"
                  label="错误信息"
                ></el-table-column>
              </el-table>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- <div class="flex-btn-right">
        <el-button size="small" @click="exportExcel">导出数据</el-button>
      </div>-->
    </el-dialog>
    <RoomOperation :operation="true" :roomInfo="roomInfo" ref="operation" />
  </div>
</template>
<script>
import {
  getRoomlList,
  addRoom,
  getFloorSelect,
  getBrandSelect,
  getHotelSelect,
  getRoomTypelSelect,
  getBuildingSelect,
  delRoom,
  editRoom,
  roomImport,
  roomTemplate,
  editUUidReq,
} from "@/api";
import { showRoomType } from "@/api/common";
import RoomOperation from "../component/RoomOperation.vue";

// import ExcelTemplate from "@/components/common/ExcelTemplate.vue";
// import { regionData, CodeToText } from "element-china-area-data";
import XLSX from "xlsx";
import FileSaver from "file-saver";

export default {
  components: {
    RoomOperation,
  },
  // name:"kf",
  data() {
    // 校验房间号
    let roomNumberVerify = (rule, value, callback) => {
      if (value === "" || value === null) {
        callback(new Error("房间号不能为空"));
      } else {
        let roomReg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
        // /^[0-9a-zA-Z]*$/g;
        if (roomReg.test(value)) {
          callback();
        } else {
          callback(new Error("只能输入中文、字母、数字"));
        }
      }
    };
    return {
      activeName: "second",
      errorData: [],
      successData: [],
      excelData: [],
      multipleSelection: [],
      loading: false,
      // 建筑列表数据
      tableData: null,
      dialogFormVisible2: null,
      dialogFormVisible: null,
      errorVisible: false, //提示信息弹窗
      foldData: false,
      dialogVisible: false,
      formLabelWidth: "100px",
      // 查询的数据
      total: null,
      roomInfo: {
        roomTypeName: null,
      },
      seekData: {
        hotelId: null,
        brandId: null,
        buildingId: null,
        floorId: null,
        roomNumber: null,
        mainBoardIp: null,
        mainBoardId: null,
        pageSize: 10,
        pageNum: 1,
      },

      // 新增
      addform: {
        hotelId: null,
        brandId: null,
        buildingId: null,
        floorId: null, //楼层ID
        remark: null, //客房描述
        buildingId: null, //建筑ID
        mainBoardIp: null, //主板IP
        mainBoardId: null, //主板ID
        roomType: null, //房间类型id
        roomNumber: null, //房间号
      },
      // 下拉框的数据
      brandSelectData: null,
      hotelSelectData: null,
      buildingSelectData: null,
      floorSelectData: null,
      roomTypeSelectData: null,

      brandSelectDatas: null,
      hotelSelectDatas: null,
      buildingSelectDatas: null,
      floorSelectDatas: null,
      roomTypeSelectDatas: null,

      selectedOptions: [],
      // 编辑
      editData: {
        mainBoardIp: null, //主板IP
        mainBoardId: null, //主板ID
        pmsRoomId: null, //PMS房间ID
        remark: null, //描述
        roomType: null, //房间类型id
        id: null, //客房ID
        roomNumber: null, //房间号码
        mainBoardType: null,
      },
      myrules: {
        brandId: [{ required: true, message: "请选择", trigger: "change" }],
        roomType: [{ required: true, message: "请选择", trigger: ["blur"] }],
        mainBoardType: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
        roomNumber: [
          { required: true, validator: roomNumberVerify, trigger: "blur" },
        ],
        mainBoardIp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        mainBoardId: [
          { required: true, message: "请输入内容", trigger: "blur" },
          { min: 10, max: 36, message: "长度 10~36 个字符", trigger: "blur" },
        ],
      },
      height: null,
      userJurisdiction: null,
      defaultProps: {
        children: "children",
        label: "title",
      },
      updatedUUid: false,
    };
  },
  computed: {
    hostType() {
      return this.$store.state.hostType;
    },
    equipmenlook() {
      return this.userJurisdiction.indexOf("equipmentInfo:look") != -1;
    },
    // 权限
    showadd: function () {
      return this.userJurisdiction.indexOf("room:add");
    },
    showdelete: function () {
      return this.userJurisdiction.indexOf("room:delete");
    },
    showupdate: function () {
      return this.userJurisdiction.indexOf("room:update");
    },
    showdownload: function () {
      return this.userJurisdiction.indexOf("room:download");
    },
    showupload: function () {
      return this.userJurisdiction.indexOf("room:upload");
    },
    roomTypeList() {
      return this.$store.state.roomTypeList;
    },
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 295;
  },
  watch: {
    errorVisible(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (!newVal) {
        this.errorData = [];
        this.successData = [];
        this.initList(this.seekData);
      }
    },
  },
  // 注册表格组件
  // components: {
  //   ExcelTemplate,
  // },
  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    // 调用初始化表格数据方法
    this.initList(this.seekData);
    this.initialize();
  },
  methods: {
    showRoomType(val) {
      return showRoomType(val, this.roomTypeList);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 下载模板
    uploadExcel() {
      roomTemplate().then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = "上传模板" + ".xlsx"; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },

    // 导出 excel
    exportExcel() {
      /* generate workbook object from table */
      var wb = XLSX.utils.table_to_book(document.querySelector("#out-table"));
      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "导入失败数据.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },

    // 导入excel
    importExcel(event) {
      let inputDOM = this.$refs.inputer;
      // 通过DOM取文件数据
      // this.fil = inputDOM.files;
      console.log(inputDOM.files[0]);
      let formData = new FormData();
      formData.append("file", inputDOM.files[0]);
      const loading = this.$loading({
        lock: true,
        text: "上传中，请稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      roomImport(formData).then((res) => {
        console.log(res);
        loading.close();
        // if (!res.data.data.error.length){
        //   this.$message.success('上传成功')
        //   this.initList(this.seekData)
        // } else {
        //   this.errorVisible = true
        //   let result = []
        //   res.data.data.error.forEach(item => {
        //     Object.keys(item).forEach(index => {
        //       result.push({index: index, value: item[index]})
        //       // result += index + ': ' + item[index] + ' | '
        //     })
        //   })
        //   console.log(result)
        //   this.errorData = result
        //   // this.$message.error(result)
        // }
        // if (res.data.message === '导入情况') {

        // }
        this.errorVisible = true;
        // 成功记录
        this.successData = res.data.data.data;

        // 失败记录
        let result = [];
        if (res.data.data.error.length) {
          res.data.data.error.forEach((item) => {
            item.errorFields.forEach((item1) => {
              result.push(Object.assign(item1, { row: item.row }));
            });
          });
        }
        this.errorData = result;
        console.log(this.errorData);
      });
    },

    importf(obj) {
      let _this = this;
      this.file = event.currentTarget.files[0];
      var rABS = false; //是否将文件读取为二进制字符串
      var file = this.file;

      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; //是否将文件读取为二进制字符串
        var pt = this;
        var workbook; //读取完成的数据
        // var excelData;
        var reader = new FileReader();
        reader.onprogress = function (e) {
          let total = file.size;
          _this.progress = (e.loaded / total) * 100;
          console.log(_this.progress);
        };
        reader.onload = function (e) {
          try {
            var bytes = new Uint8Array(reader.result);
            var length = bytes.byteLength;
            for (var i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            if (rABS) {
              workbook = XLSX.read(btoa(fixdata(binary)), {
                //手动转化
                type: "base64",
              });
            } else {
              workbook = XLSX.read(binary, {
                type: "binary",
              });
            }
            // excelData = [];
          } catch (e) {
            console.log("文件类型不正确");
            return;
          }
          var fromTo = "";
          for (var sheet in workbook.Sheets) {
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              fromTo = workbook.Sheets[sheet]["!ref"];
              _this.excelData = _this.excelData.concat(
                XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
              );
            }
          }
          console.log(_this.excelData);
          roomImport(_this.excelData).then((res) => {
            console.log("批量", res);
          });
        };

        reader.readAsArrayBuffer(f);
      };

      var reader = new FileReader();
      if (rABS) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsBinaryString(file);
      }
    },

    // 初始化表格数据
    initList(obj) {
      this.loading = true;
      getRoomlList(obj).then((res) => {
        // console.log(res)
        if (res.status === 200) {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.seekData.pageNum > 1 && res.data.data.rows == false) {
            this.seekData.pageNum = this.seekData.pageNum - 1;
            this.initList(this.seekData);
          }
        }
      });
    },

    /* 初始化下拉框 */
    // 获取品牌下拉框
    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
          this.brandSelectDatas = res.data.data;
        }
      });
    },
    // 获取门店下拉框
    selectOne(id) {
      this.addform.hotelId = null;
      this.addform.buildingId = null;
      this.addform.floorId = null;
      this.addform.roomType = null;
      getHotelSelect(id).then((res) => {
        if (res.data.data.length > 0) {
          this.hotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店数据",
            type: "warning",
          });
        }
      });
      // 获取房间类型下拉框
      getRoomTypelSelect(id).then((res) => {
        if (res.status === 200) {
          this.roomTypeSelectData = res.data.data;
          // console.log(res)
        }
      });
    },

    // 选择框
    selectOnes(id) {
      this.seekData.hotelId = null;
      this.seekData.buildingId = null;
      this.seekData.floorId = null;
      getHotelSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data.length > 0) {
          this.hotelSelectDatas = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店数据",
            type: "warning",
          });
        }
      });
      // 获取房间类型下拉框
      getRoomTypelSelect(id).then((res) => {
        if (res.status === 200) {
          this.roomTypeSelectDatas = res.data.data;
          // console.log(res)
        }
      });
    },
    // 获取建筑下拉框
    selectTwo(id) {
      this.addform.buildingId = null;
      this.addform.floorId = null;
      this.buildingSelectData = [];
      getBuildingSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data.length > 0) {
          this.buildingSelectData = res.data.data;
          // this.seekData.buildingId = res.data[0].id
          // this.selectThree(this.seekData.buildingId)
        } else {
          this.$message({
            message: "该门店下没有建筑数据",
            type: "warning",
          });
        }
      });
    },

    selectTwos(id) {
      this.seekData.buildingId = null;
      this.seekData.floorId = null;
      this.buildingSelectDatas = [];
      getBuildingSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data.length > 0) {
          this.buildingSelectDatas = res.data.data;
          this.seekData.buildingId = res.data.data[0].id;
          this.selectThrees(this.seekData.buildingId);
        } else {
          this.$message({
            message: "该门店下没有建筑数据",
            type: "warning",
          });
        }
      });
    },
    // 获取楼层下拉框
    selectThrees(id) {
      this.seekData.floorId = null;
      console.log("selectThrees");
      getFloorSelect(id).then((res) => {
        console.log(res);
        if (res.data.data.length > 0) {
          this.floorSelectDatas = res.data.data;
        } else {
          this.$message({
            message: "该建筑下没有楼层数据",
            type: "warning",
          });
        }
      });
    },

    selectThree(id) {
      this.addform.floorId = null;
      getFloorSelect(id).then((res) => {
        console.log(res);
        if (res.data.data.length > 0) {
          this.floorSelectData = res.data.data;
        } else {
          this.$message({
            message: "该建筑下没有楼层数据",
            type: "warning",
          });
        }
      });
    },
    /* 展开搜索头部 */
    fold() {
      this.foldData = !this.foldData;
      if (this.foldData) {
        this.initialize();
      }
    },

    /* 新增弹窗 */
    // 获取品牌下拉框
    addBtn() {
      this.dialogFormVisible = true;
      // this.reset()
      this.initialize();
    },

    // 确定按钮
    confirmAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          addRoom(this.addform)
            .then((res) => {
              this.loading = false;
              if (res.data.code == "000000") {
                this.$message.success(res.data.message);
                this.initList(this.seekData);
                // this.reset()
                this.dialogFormVisible = false;
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消按钮
    abrogateAdd() {
      this.dialogFormVisible = false;
    },

    // 门店删除
    handleDelete(row) {
      // console.log(row)
      this.$confirm("此操作将永久删除该房间, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 点击确定执行的操作
          // console.log(row.id)
          delRoom(row.id).then((res) => {
            console.log(res);
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.initList(this.seekData);
              // this.reset()
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    batchesDelete() {
      if (this.multipleSelection.length != 0) {
        // 把要删除的用户ID以字符串拼接
        let number = "";
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          number += element.id + ",";
        }
        number = number.slice(0, number.length - 1); //去掉最后的逗号
        this.$confirm("此操作将永久删除所选择房间, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            delRoom(number).then((res) => {
              this.loading = false;
              if (res.data.code == "000000") {
                this.$message.success(res.data.message);
                this.initList(this.seekData);
                // this.reset()
              } else {
                this.$message.error(res.data.message);
              }
            });
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.warning("请先选择要删除的数据");
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },

    // 编辑门店
    handleEdit(index, updatedUUid) {
      console.log(index, updatedUUid);
      this.roomInfo = JSON.parse(JSON.stringify(index));
      // 获取房间类型下拉框
      getRoomTypelSelect(index.brandId).then((res) => {
        if (res.status === 200) {
          this.roomTypeSelectData = res.data.data;
        }
      });
      [
        "mainBoardIp",
        "mainBoardId",
        "remark",
        "roomType",
        "roomNumber",
        "pmsRoomId",
        "id",
        "floorId",
        "buildingId",
        "mainBoardType",
      ].forEach((key) => {
        this.editData[key] = index[key];
      });
      //  this.editData.mainBoardIp = index.mainBoardIp;
      //  this.editData.mainBoardId = index.mainBoardId;
      //  this.editData.remark = index.remark;
      //  this.editData.roomType = index.roomType;
      //  this.editData.roomNumber = index.roomNumber;
      //  this.editData.pmsRoomId = index.pmsRoomId;
      //  this.editData.id = index.id;
      //  this.editData.floorId = index.floorId;
      //  this.editData.buildingId = index.buildingId;
      this.dialogFormVisible2 = true;
      this.updatedUUid = updatedUUid ? updatedUUid : false;
    },
    openDialog(val) {
      if (this.equipmenlook) {
        console.log(this.roomInfo);
        // this.roomInfo.roomTypeName=this.roomInfo.
        // this.getRoomDeviceInfo(val.mainboardId);
        this.$refs.operation.getRoomDeviceInfo(this.roomInfo.mainBoardId);
      } else {
        if (!this.equipmenlook) {
          this.$message({
            type: "error",
            message: "暂无权限，无法查看！",
          });
        } else if (val.basicstatus != "on") {
          this.$message({
            type: "error",
            message: "设备未连接",
          });
        }
      }
    },
    // 编辑楼层确认
    confirmEditD(formName) {
      // console.log(this.editData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.updatedUUid) {
            let obj = new Object();
            obj.id = this.editData.id;
            obj.mainBoardId = this.editData.mainBoardId;
            this.editUUidReq(obj);
          } else {
            this.editRoom();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editUUidReq(data) {
      this.loading = true;
      console.log(data);
      editUUidReq(data)
        .then((res) => {
          this.dialogFormVisible2 = false;
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success(res.data.message);
            this.initList(this.seekData);
            // this.reset()
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    editRoom() {
      this.loading = true;
      editRoom(this.editData).then((res) => {
        this.loading = false;
        if (res.data.code == "000000") {
          this.$message.success(res.data.message);
          this.initList(this.seekData);
          // this.reset()
        } else {
          this.$message.error(res.data.message);
        }
        this.dialogFormVisible2 = false;
      });
    },

    handleCancelEdit() {
      this.dialogFormVisible2 = false;
    },
    // 查询按钮
    handleSearch() {
      // console.log(this.seekData)
      this.loading = true;
      this.seekData.pageNum = 1;
      if (this.seekData) {
        getRoomlList(this.seekData).then((res) => {
          // console.log(res)
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        });
      } else {
        this.initList(this.seekData);
      }
    },
    // 重置按钮
    reset() {
      this.seekData.brandId = null;
      this.seekData.hotelId = null;
      this.seekData.buildingId = null;
      this.seekData.floorId = null;
      this.seekData.roomNumber = null;
      this.seekData.mainBoardIp = null;
      this.seekData.mainBoardId = null;
      this.seekData.pageNum = 1;

      this.hotelSelectData = null;
      this.buildingSelectData = null;
      this.floorSelectData = null;
      this.initList(this.seekData);
    },
    //分页
    handleCurrentChange(cpage) {
      this.seekData.pageNum = cpage;
      this.initList(this.seekData);
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      //  this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-btn-right {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.my-box {
  .astrict {
    .el-select {
      width: 100%;
    }
  }
  .my-iemt {
    span {
      font-size: 14px;
    }
    white-space: nowrap;
  }
  // .my-input {
  //   max-width: 200px;
  // }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;
    .el-input__inner {
      max-width: 190px;
    }
    span {
      font-size: 14px;
    }
  }
}

.file {
  position: relative;
  padding: 9px 15px;
  margin-left: 10px;
  font-size: 12px;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
}
.file input {
  position: absolute;
  font-size: 18px;
  right: 0;
  top: 0;
  opacity: 1;
  z-index: -1;
  border: 1px solid black;
}
</style>
