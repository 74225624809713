var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "my-box",
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-card",
            { attrs: { shadow: "always" } },
            [
              _c(
                "el-form",
                { staticClass: "topS", attrs: { "label-width": "50px" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { gutter: 10 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "品牌" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.selectOnes },
                                  model: {
                                    value: _vm.seekData.brandId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.seekData, "brandId", $$v)
                                    },
                                    expression: "seekData.brandId",
                                  },
                                },
                                _vm._l(
                                  _vm.brandSelectDatas,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.brandName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "门店" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.selectTwos },
                                  model: {
                                    value: _vm.seekData.hotelId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.seekData, "hotelId", $$v)
                                    },
                                    expression: "seekData.hotelId",
                                  },
                                },
                                _vm._l(
                                  _vm.hotelSelectDatas,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "建筑" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.selectThrees },
                                  model: {
                                    value: _vm.seekData.buildingId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.seekData, "buildingId", $$v)
                                    },
                                    expression: "seekData.buildingId",
                                  },
                                },
                                _vm._l(
                                  _vm.buildingSelectDatas,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.buildingName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "楼层" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.seekData.floorId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.seekData, "floorId", $$v)
                                    },
                                    expression: "seekData.floorId",
                                  },
                                },
                                _vm._l(
                                  _vm.floorSelectDatas,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.floorName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "房间号", "label-width": "60px" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入内容",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.seekData.roomNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.seekData, "roomNumber", $$v)
                                  },
                                  expression: "seekData.roomNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "主板IP", "label-width": "60px" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入内容",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.seekData.mainBoardIp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.seekData, "mainBoardIp", $$v)
                                  },
                                  expression: "seekData.mainBoardIp",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "UUID" } },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入内容",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.seekData.mainBoardId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.seekData, "mainBoardId", $$v)
                                  },
                                  expression: "seekData.mainBoardId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 19 } },
                    [
                      _vm.showadd != -1
                        ? _c("el-button", { on: { click: _vm.addBtn } }, [
                            _vm._v("新增"),
                          ])
                        : _vm._e(),
                      _vm.showdelete != -1
                        ? _c(
                            "el-button",
                            { on: { click: _vm.batchesDelete } },
                            [_vm._v("批量删除")]
                          )
                        : _vm._e(),
                      _vm.showdownload != -1
                        ? _c("el-button", { on: { click: _vm.uploadExcel } }, [
                            _vm._v("下载模板"),
                          ])
                        : _vm._e(),
                      _vm.showupload != -1
                        ? _c("label", { staticClass: "control-label file" }, [
                            _c("input", {
                              ref: "inputer",
                              staticClass: "upload",
                              attrs: {
                                type: "file",
                                accept:
                                  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                              },
                              on: { change: _vm.importExcel },
                            }),
                            _vm._v("\n            导入excel\n          "),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#fff",
                            display: "inline-block",
                          },
                        },
                        [_vm._v(".")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "reset-button", attrs: { span: 5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("el-button", { on: { click: _vm.reset } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-row", { staticStyle: { display: "none" } }),
      _c(
        "el-row",
        [
          _c("el-card", { attrs: { shadow: "always" } }, [
            _c(
              "div",
              { staticClass: "my-header-table" },
              [
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      height: _vm.height,
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "brandName", label: "品牌", width: "80" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "hotelName", label: "门店", width: "250" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "id", label: "客房ID" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "floorName",
                        label: "楼层",
                        sortable: "",
                        width: "80",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "roomTypeName",
                        label: "房间类型",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "roomNumber",
                        label: "房间号",
                        sortable: "",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mainBoardIp",
                        label: "主板IP",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mainBoardId",
                        label: "UUID",
                        "min-width": "280",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "300", fixed: "right" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.showupdate != -1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        title: "修改UUID",
                                        size: "mini",
                                        dialogFormVisible: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(
                                            scope.row,
                                            "updatedUUid"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("UUID修改")]
                                  )
                                : _vm._e(),
                              _vm.showupdate != -1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        title: "修改客房",
                                        type: "primary",
                                        size: "mini",
                                        dialogFormVisible: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.showdelete != -1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        title: "删除",
                                        size: "mini",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next, sizes, total, jumper",
                    "page-sizes": [10, 20, 50, 100],
                    "page-size": _vm.seekData.pageSize,
                    "current-page": _vm.seekData.pageNum,
                    total: _vm.total,
                  },
                  on: {
                    "current-change": _vm.handleCurrentChange,
                    "size-change": _vm.handleSizeChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: {
            title: _vm.updatedUUid ? "修改UUID" : "编辑客房",
            visible: _vm.dialogFormVisible2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editData",
              attrs: { model: _vm.editData, rules: _vm.myrules },
            },
            [
              !_vm.updatedUUid
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "主板IP",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", maxlength: "20" },
                        model: {
                          value: _vm.editData.mainBoardIp,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editData,
                              "mainBoardIp",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "editData.mainBoardIp",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "UUID",
                    prop: "mainBoardId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: !_vm.updatedUUid,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.editData.mainBoardId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "mainBoardId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.mainBoardId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "主机类型",
                    prop: "mainBoardType",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.editData.mainBoardType,
                        callback: function ($$v) {
                          _vm.$set(_vm.editData, "mainBoardType", $$v)
                        },
                        expression: "editData.mainBoardType",
                      },
                    },
                    _vm._l(_vm.hostType, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              !_vm.updatedUUid
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "房间类型",
                        prop: "roomType",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.editData.roomType,
                            callback: function ($$v) {
                              _vm.$set(_vm.editData, "roomType", $$v)
                            },
                            expression: "editData.roomType",
                          },
                        },
                        _vm._l(_vm.roomTypeSelectData, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.typeName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.updatedUUid
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "房间号",
                        prop: "roomNumber",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", maxlength: "15" },
                        model: {
                          value: _vm.editData.roomNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editData,
                              "roomNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "editData.roomNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.updatedUUid
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "PMS房间ID",
                        prop: "pmsRoomId",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", maxlength: "15" },
                        model: {
                          value: _vm.editData.pmsRoomId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editData,
                              "pmsRoomId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "editData.pmsRoomId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.updatedUUid
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "描述",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", maxlength: "200" },
                        model: {
                          value: _vm.editData.remark,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editData,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "editData.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.updatedUUid
                ? _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": _vm.formLabelWidth } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.openDialog },
                        },
                        [_vm._v("查看设备信息")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelEdit } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmEditD("editData")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: { title: "新增客房", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: { model: _vm.addform, rules: _vm.myrules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "品牌",
                    prop: "brandId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectOne },
                      model: {
                        value: _vm.addform.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "brandId", $$v)
                        },
                        expression: "addform.brandId",
                      },
                    },
                    _vm._l(_vm.brandSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.brandName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "门店",
                    prop: "brandId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectTwo },
                      model: {
                        value: _vm.addform.hotelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "hotelId", $$v)
                        },
                        expression: "addform.hotelId",
                      },
                    },
                    _vm._l(_vm.hotelSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "建筑",
                    prop: "brandId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectThree },
                      model: {
                        value: _vm.addform.buildingId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "buildingId", $$v)
                        },
                        expression: "addform.buildingId",
                      },
                    },
                    _vm._l(_vm.buildingSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.buildingName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "楼层",
                    prop: "brandId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.floorId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "floorId", $$v)
                        },
                        expression: "addform.floorId",
                      },
                    },
                    _vm._l(_vm.floorSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.floorName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "客房类型",
                    prop: "brandId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.roomType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "roomType", $$v)
                        },
                        expression: "addform.roomType",
                      },
                    },
                    _vm._l(_vm.roomTypeSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.typeName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "房间号",
                    prop: "roomNumber",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "15" },
                    model: {
                      value: _vm.addform.roomNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "roomNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.roomNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "PMS房间ID",
                    prop: "pmsRoomId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "15" },
                    model: {
                      value: _vm.addform.pmsRoomId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "pmsRoomId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.pmsRoomId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "主板IP", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "20" },
                    model: {
                      value: _vm.addform.mainBoardIp,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "mainBoardIp",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.mainBoardIp",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "UUID",
                    prop: "mainBoardId",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "36" },
                    model: {
                      value: _vm.addform.mainBoardId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "mainBoardId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.mainBoardId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "主机类型",
                    prop: "mainBoardType",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.mainBoardType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "mainBoardType", $$v)
                        },
                        expression: "addform.mainBoardType",
                      },
                    },
                    _vm._l(_vm.hostType, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "200" },
                    model: {
                      value: _vm.addform.remark,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "remark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.abrogateAdd } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: { title: "上传信息", visible: _vm.errorVisible },
          on: {
            "update:visible": function ($event) {
              _vm.errorVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "div",
                {
                  staticClass: "flex-btn-right",
                  staticStyle: {
                    position: "absolute",
                    top: "3px",
                    right: "0",
                    "z-index": "1",
                  },
                },
                [
                  _c("span", { staticStyle: { margin: "6px" } }, [
                    _vm._v(
                      "成功 " +
                        _vm._s(_vm.successData.length) +
                        " 条，失败\n          " +
                        _vm._s(_vm.errorData.length) +
                        " 条，合计\n          " +
                        _vm._s(_vm.successData.length + _vm.errorData.length) +
                        " 条"
                    ),
                  ]),
                  _vm.activeName === "second"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.exportExcel },
                        },
                        [_vm._v("导出数据")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "成功记录", name: "first" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.successData },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "行", prop: "row", width: "80" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "房间号",
                              prop: "roomNumber",
                              width: "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "主板ID", prop: "mainBoardId" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "导入时间",
                              prop: "createTime",
                              width: "140",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "失败记录", name: "second" } },
                    [
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.errorData, id: "out-table" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "row", label: "行", width: "80" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "cellIndex",
                                label: "列",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "column",
                                label: "字段",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "errorMessage",
                                label: "错误信息",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("RoomOperation", {
        ref: "operation",
        attrs: { operation: true, roomInfo: _vm.roomInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }